import React, { useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Module } from '../../Module';
import { FormEventsByClient } from '../../charts/FormEventsByClient';
import { FormEventsAllUsers } from '../../charts/FormEventsAllUsers';
import { WorkflowClient } from '../../charts/WorkflowClient';
import { WorkflowClientTop } from '../../charts/WorkflowClientTop';
import { PublicApiClient } from '../../charts/PublicApiClient';
import { PublicApiClientHourly } from '../../charts/PublicApiClientHourly';
import { PublicApiAuth } from '../../charts/PublicApiAuth';
import './Metrics.scss';
import {Button, Icon} from "semantic-ui-react";
import {formatDateRangeLabel} from "../../../utility/formatting";
import DateRangePickerModal from "../../DateRangePicker/DateRangePickerModal";
import {RangeModifier} from "react-day-picker";

export type MetricsProps = {
  id: string;
  account: TypeMePlease;
};
export function Metrics(props: MetricsProps) {
  const { id, account } = props;
  const [startDate, setStartDate] = useState(moment().days(-7).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const handleDateChange = (range: RangeModifier) => {
    setStartDate(moment(range.from).format('YYYY-MM-DD'));
    setEndDate(moment(range.to).format('YYYY-MM-DD'));
    handleClose();
  }

  return (
    <div className="metrics">
      <Helmet>
        <title>{account.name} - Metrics</title>
      </Helmet>
      <div className="modules">
        <div className="control-bar">
          <div className="controls">
            <div className="control">
              <Button onClick={handleOpen}>
                <Icon name="calendar outline" />
                {formatDateRangeLabel(startDate, endDate)}
              </Button>
              <DateRangePickerModal open={open} onClose={handleClose} onDoneClick={handleDateChange} />
            </div>
          </div>
        </div>
        <Module title="Form Events">
          <FormEventsByClient eventTypes={['created', 'completed']} clientId={id} startDate={startDate} endDate={endDate} width={500} height={300} />
        </Module>
        <Module title="Top User Form Completions">
          <FormEventsAllUsers eventType="completed" clientId={id} startDate={startDate} endDate={endDate} width={500} height={325} topCount={20} />
        </Module>
        <Module title="Workflows">
          <WorkflowClient clientId={id} startDate={startDate} endDate={endDate}  width={500} height={400} />
        </Module>
        <Module title="Top Workflows">
          <WorkflowClientTop clientId={id} startDate={startDate} endDate={endDate}  width={500} height={400} />
        </Module>
        <Module title="API Daily Usage">
          <PublicApiClient clientId={id} startDate={startDate} endDate={endDate}  width={500} height={400} />
        </Module>
        <Module title="API Hourly Usage">
          <PublicApiClientHourly clientId={id} startDate={startDate} endDate={endDate}  width={500} height={400} useLegend={false} />
        </Module>
        <Module title="API Authorization">
          <PublicApiAuth clientId={id} startDate={startDate} endDate={endDate}  width={500} />
        </Module>
      </div>
    </div>
  );
}
