import React, { useState, useEffect } from 'react';
import { useApi, useApim } from '../../../hooks/useApi';
import { apiCall } from '../../../utility/api';
import './ApiConfigs.scss';
import Loader from '../../Loader';
import { Helmet } from 'react-helmet';
import { Table, Button } from 'semantic-ui-react';
import { ApiThrottlingLimitsDropdown } from '../../apiConfigs/ApiThrottlingLimitsDropdown';
import { ApiConfigsDto, ApiThrottlingScope, ApiThrottlingLimits, ApiThrottlingDisplay } from '../../apiConfigs/apiConfigs-types';
import { CommentInput } from '../../commentinput/CommentInput';
import { AppFeatures, canIUse } from '../../../utility/SecurityHelper';

export type ApiConfigsProps = {
  id: string;
  account: Account;
};

type Account = {
  name: string;
  subscriptionCode: string;
  apiLimitDay?: number;
  apiLimitHour?: number;
  apiLimitMinute?: number;
  deletedDate?: string;
}

type Edition = {
  code: string;
  apiConfigs: ApiConfigsDto;
};

export default function ApiConfigs(props: ApiConfigsProps) {
  const { id, account } = props;
  const [selectedThrottlingLimits, setSelectedThrottlingLimits] = useState<ApiThrottlingLimits>();
  const [comments, setComments] = useState<string>();
  const [saving, setSaving] = useState(null);
  const [supportedLimits, supportedLimitsLoading] = useApi<TypeMePlease>('api/account/apiconfigs/limits');
  const [editions, editionsLoading, editionsLoaded] = useApim<Edition[]>('/editions');
  const [clientEdition, setClientEdition] = useState<Edition>();
  const [editionApiThrottlingDefaults, setEditionApiThrottlingDefaults] = useState<ApiThrottlingDisplay>();
  const [effectiveThrottlingLimits, setEffectiveThrottlingLimits] = useState<ApiThrottlingDisplay>();
  const isReadOnly = !canIUse(AppFeatures.EditAccountControls); 
  useEffect(() => {
    if (!editionsLoaded) {
      return;
    }

    const edition = editions.find(x => x.code == account.subscriptionCode);
    if (!edition || !edition.apiConfigs) {
      return;
    }

    setClientEdition(edition);
    setEditionApiThrottlingDefaults(getDisplayApiThrottlingLimits(edition.apiConfigs));
    setSelectedThrottlingLimits({
      day: props.account.apiLimitDay,
      hour: props.account.apiLimitHour,
      minute: props.account.apiLimitMinute
    });
  }, [editions, editionsLoaded])

  useEffect(() => {
    if (!props.account || !editionsLoaded || !clientEdition)
    {
      return;
    }

    setEffectiveThrottlingLimits(getDisplayApiThrottlingLimits({
      apiLimitDay: selectedThrottlingLimits.day > -1 && clientEdition.apiConfigs?.apiLimitDay > 0 && (selectedThrottlingLimits.day === 0 || selectedThrottlingLimits.day > clientEdition.apiConfigs?.apiLimitDay) ? selectedThrottlingLimits.day : clientEdition.apiConfigs.apiLimitDay,
      apiLimitHour: selectedThrottlingLimits.hour > -1 && clientEdition.apiConfigs?.apiLimitHour > 0 && (selectedThrottlingLimits.hour === 0 || selectedThrottlingLimits.hour > clientEdition.apiConfigs?.apiLimitHour) ? selectedThrottlingLimits.hour : clientEdition.apiConfigs.apiLimitHour,
      apiLimitMinute: selectedThrottlingLimits.minute > -1 && clientEdition.apiConfigs?.apiLimitMinute > 0 && (selectedThrottlingLimits.minute === 0 || selectedThrottlingLimits.minute > clientEdition.apiConfigs?.apiLimitMinute) ? selectedThrottlingLimits.minute : clientEdition.apiConfigs.apiLimitMinute
    }));
  }, [selectedThrottlingLimits, props.account, editionsLoaded, clientEdition]);

  const updateThrottlingLimits = (scope: ApiThrottlingScope, value: number) => {
    const limits : ApiThrottlingLimits = {
      day: scope === ApiThrottlingScope.day ? value : selectedThrottlingLimits.day,
      hour: scope === ApiThrottlingScope.hour ? value : selectedThrottlingLimits.hour,
      minute: scope === ApiThrottlingScope.minute ? value : selectedThrottlingLimits.minute,
    };
    setSelectedThrottlingLimits(limits);
  };

  const save = async () => {
    setSaving(true);
    await apiCall(`/api/account/${id}/apiconfigs`, 'PUT', {
      apiLimitDay: selectedThrottlingLimits.day,
      apiLimitHour: selectedThrottlingLimits.hour,
      apiLimitMinute: selectedThrottlingLimits.minute,
      comments,
    });

    props.account.apiLimitDay = selectedThrottlingLimits.day;
    props.account.apiLimitHour = selectedThrottlingLimits.hour;
    props.account.apiLimitMinute = selectedThrottlingLimits.minute;

    setComments('');
    setSaving(false);
  };

  return (
    <div className="api-configs">
      <Loader isLoading={supportedLimitsLoading || editionsLoading} />
      <Helmet>
        <title>{account.name} - API Configs</title>
      </Helmet>
      <h3>API Configurations</h3>
      <div className="form-wrapper form-width">
        <div className="ui header header-cell">Throttling Limits</div>
        <Table celled>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Throttling Limits</Table.Cell>
              <Table.Cell>Client Overrides</Table.Cell>
              <Table.Cell>Edition Defaults</Table.Cell>
              <Table.Cell>Effective Setting</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Per Day</Table.Cell>
              <Table.Cell>
                <ApiThrottlingLimitsDropdown 
                  supportedLimits={supportedLimits}
                  scope={ApiThrottlingScope.day}
                  value={selectedThrottlingLimits?.day}
                  onChange={x => updateThrottlingLimits(ApiThrottlingScope.day, x)} 
                  readOnly={isReadOnly} />
              </Table.Cell>
              <Table.Cell>{editionApiThrottlingDefaults?.day}</Table.Cell>
              <Table.Cell>{effectiveThrottlingLimits?.day}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Per Hour</Table.Cell>
              <Table.Cell>
                <ApiThrottlingLimitsDropdown 
                  supportedLimits={supportedLimits} 
                  scope={ApiThrottlingScope.hour}
                  value={selectedThrottlingLimits?.hour}
                  onChange={x => updateThrottlingLimits(ApiThrottlingScope.hour, x)}
                  readOnly={isReadOnly} />
              </Table.Cell>
              <Table.Cell>{editionApiThrottlingDefaults?.hour}</Table.Cell>
              <Table.Cell>{effectiveThrottlingLimits?.hour}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Per Minute</Table.Cell>
              <Table.Cell>
                <ApiThrottlingLimitsDropdown 
                  supportedLimits={supportedLimits} 
                  scope={ApiThrottlingScope.minute}
                  value={selectedThrottlingLimits?.minute}
                  onChange={x => updateThrottlingLimits(ApiThrottlingScope.minute, x)} 
                  readOnly={isReadOnly} />
              </Table.Cell>
              <Table.Cell>{editionApiThrottlingDefaults?.minute}</Table.Cell>
              <Table.Cell>{effectiveThrottlingLimits?.minute}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      {account.deletedDate ? null : (
        <div className="ui form form-width">
          <br />
          <CommentInput placeholder="Comment." value={comments} onChange={setComments}/>
          <Button className="primary" onClick={save} loading={saving} disabled={!comments}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

function getDisplayApiThrottlingLimits(configs: ApiConfigsDto) : ApiThrottlingDisplay {
  return {
    day: configs.apiLimitDay === 0 ? 'Unlimited' : !configs.apiLimitDay ? 'Not Set' : configs.apiLimitDay,
    hour: configs.apiLimitHour === 0 ? 'Unlimited' : !configs.apiLimitHour ? 'Not Set' : configs.apiLimitHour,
    minute: configs.apiLimitMinute === 0 ? 'Unlimited' : !configs.apiLimitMinute ? 'Not Set' : configs.apiLimitMinute
  }
}
