import React from 'react';

export type AuditProps = {
  id: string;
  account: TypeMePlease;
};

export function Audit(props: AuditProps) {
  const { id } = props;
  return (
    <div className="settings">
      <table className="ui celled table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a href={`/Reports/Authentication?clientId=${id}`}>Login Report</a>
            </td>
            <td>Report for all client logins. Searchable by username and date range</td>
          </tr>
          <tr>
            <td>
              <a href={`/Reports/SSO?clientId=${id}`}>SSO Mapping</a>
            </td>
            <td>SSO User name mapping</td>
          </tr>
          <tr>
            <td>
              <a href={`/Reports/PublicShare?clientId=${id}`}>Public Form Report</a>
            </td>
            <td>Report for all client Public Share submissions group by month</td>
          </tr>
          <tr>
            <td>
              <a href={`/Reports/AuditLog?clientId=${id}`}>Tools Site Audit Log</a>
            </td>
            <td>Audit log for all tools site actions performed on client</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
