import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useApi } from '../../../hooks/useApi';
import Loader from '../../Loader';
import './AccountFeatures.scss';
import { Popup, Button, Search, Table, Modal } from 'semantic-ui-react';
import { useProfile } from '../../../context/ProfileContext';
import { apiCall } from '../../../utility/api';
import { FeatureFlagDataType  } from '../../featureflags/types';
import { FeatureFlagValue } from '../../featureflags/FeatureFlagValue';
import { useMessages } from '../../../context/MessagesContext';
import { CommentInput } from '../../commentinput/CommentInput';

interface AccountFeature {
  featureFlagId: string;
  flagName: string;
  flagCodeName: string;
  description: string;
  category: string;
  clientControlled: boolean;
  dataType: FeatureFlagDataType;
  isDefaultValue: string;
  value: string | number | boolean;
  readGroupIds: string;
  writeGroupIds: string;
  lastUpdatedDate: Date;
}

interface AccountFeatureUpdate { 
  features: { [id: string]: string | number | boolean };
  comments?: string;
}

export type AccountFeaturesProps = {
  id: string;
  account: TypeMePlease;
  refreshAccount: () => void;
};

export function AccountFeatures(props: AccountFeaturesProps) {
  const { id, account, refreshAccount } = props;
  const { profile } = useProfile();
  const [accountFeatures, _accountFeaturesLoading, accountFeaturesLoaded, refreshAccountFeatures] = useApi<(AccountFeature & {hasUpdate: boolean})[]>(`/api/account/${id}/features`);
  const [isSaving, setIsSaving] = useState(false);
  const [updates, setUpdates] = useState<AccountFeatureUpdate>({ features: {}});
  const [deleteOverrideModalOpen, setDeleteOverrideModalOpen] = useState(false);
  const [deleteOverrideComment, setDeleteOverrideComment] = useState<string>();
  const [deleteOverrideFeatureFlagId, setDeleteOverrideFeatureFlagId] = useState<string>(null);
  const [searchResults, setSearchResults] = useState([]);
  const { showMessage } = useMessages();

  useEffect(() => {
    setUpdates({ features: {}});
  }, [accountFeatures]);

  const featuresForTable = (accountFeatures || []).map(feature => {
    const row = { ...feature };
    const featureUpdates = updates?.features || {};
    if(featureUpdates.hasOwnProperty(row.featureFlagId)) { 
      row.value = featureUpdates[row.featureFlagId];
      row.hasUpdate = true;
    }
    return row;
  });
  const readFeatureFlags = (featuresForTable || [])
    .filter((af) => af.readGroupIds == null || af.readGroupIds.split(',').some((groupId) => profile.groupIds.indexOf(groupId) > -1));
  const writeFeatureFlags = (featuresForTable || [])
    .filter((af) => af.writeGroupIds == null || af.writeGroupIds.split(',').some((groupId) => profile.groupIds.indexOf(groupId) > -1));

  const categories = (readFeatureFlags || []).map(ff => ff.category)
    .filter((cat, idx, arr) => arr != null && cat != null && arr.indexOf(cat) === idx)
    .sort();

  const getAccountFeaturesByCategory = (category: string) => readFeatureFlags.filter(af => af.category === category)
    .sort((af1, af2) => af1.flagName > af2.flagName? 1 : -1);

  const isReadOnly = (accountFeature: AccountFeature) => !writeFeatureFlags.some(wff => wff.featureFlagId === accountFeature.featureFlagId);
  
  const setComments = (comments: string) => {
    setUpdates({ ...updates, comments: comments });
  }

  const setAccountFeatureValue = (accountFeature: AccountFeature, value: string | number | boolean) => {
    
    setUpdates({ ...updates, features: { ...(updates?.features || {}), [accountFeature.featureFlagId]: value }});
  }

  const canSave = () => { 
    return updates?.comments != null && updates?.comments.replace(' ', '') !== '';
  }

  const onSearchClick =(_, data) => {
    document.getElementById(data.result.id)?.scrollIntoView();
  }

  const handleSearchChange = React.useCallback((e, data) => {
    const escapeRegExp = (regVal :string) => {
      return (regVal ?? '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    const reg = new RegExp(escapeRegExp(data.value ??  ''), 'i')
    const isMatch = (result: AccountFeature) => reg.test(result.flagName) || reg.test(result.flagCodeName) || reg.test(result.description) || reg.test(result.category);
  
    const searchResults = (readFeatureFlags).filter(isMatch).map(featureFlag => ({
      id: featureFlag.featureFlagId,
      title: `${featureFlag.flagName} (${featureFlag.category})`,
      description: featureFlag.flagName,
    }))
    setSearchResults(searchResults);
  }, [accountFeatures]);
  
  if (!accountFeaturesLoaded) {
    return <Loader isLoading />;
  }

  const onDeleteOverrideConfirmClick = async () => { 
    
    const featureFlagId = deleteOverrideFeatureFlagId;
    const comment = deleteOverrideComment;
    closeDeleteOverrideModal();

    if(!comment) { 
      return showMessage('Please add a comment before deleting an override');
    }
    
    setIsSaving(true);
    const trySave = async () => { 
      try
      {
        const result = await apiCall(`/api/account/${id}/features/${featureFlagId}`, 'DELETE', { comments: comment });
        if(result.status !== 200) { 
          console.log(result.data)
          showMessage('Failed to remove override');
          return;
        }
        refreshAccountFeatures();
        refreshAccount();
      } catch(e) { 
        console.log(e);
        showMessage('Failed to remove override');
      }
    }
    
    await trySave();
    setIsSaving(false); 
  }

  const closeDeleteOverrideModal = async () => { 
    setDeleteOverrideComment(null);
    setDeleteOverrideModalOpen(false);
  }

  const onDeleteOverrideClick = async (featureFlagId) => { 
    setDeleteOverrideFeatureFlagId(featureFlagId);
    setDeleteOverrideModalOpen(true);
  }

  const onSaveClick = async () => { 
    if(!canSave()) { 
      return showMessage('Please add a comment before saving');
    }

    setIsSaving(true);
    const trySave = async () => { 
      try
      {
        const result = await apiCall(`/api/account/${id}/features`, 'PUT', updates);

        if(result.status !== 200) { 
          console.log(result.data)
          showMessage('Failed to save account feature updates');
          return;
        }
        refreshAccountFeatures();
        refreshAccount();
      } catch(e) { 
        console.log(e);
        showMessage('Failed to save account feature updates');
      }
    }
    
    await trySave();
    setIsSaving(false); 
  }

  return (
    <div className="account-features">
      <Helmet>
        <title>{account.name} - Features</title>
      </Helmet>
      <div className="page-header">
        <h3>Features</h3>
        <span className='subtext'>*Highlighted Features Have Client Overrides & Are Not Using The Defaults</span>
        <div className="search-header">
          <Search
              placeholder='Search...'
              onResultSelect={onSearchClick}
              onSearchChange={handleSearchChange}
              results={searchResults}
          />
        </div>
      </div>
      <div className='features-list'> 
      {categories.map(c => (
        <Table singleLine key={c}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='4'><h3>{c}</h3></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {getAccountFeaturesByCategory(c).map(accountFeature => (
              <Table.Row key={accountFeature.featureFlagId} id={accountFeature.featureFlagId} 
                className={accountFeature.hasUpdate ? 'update-value' : accountFeature.isDefaultValue ? 'default-value' : 'client-override-value'}>
                <Table.Cell className='flag-name'><span>{accountFeature.flagName}</span>
                  {accountFeature.description && <Popup content={accountFeature.description} trigger={<i className="ui icon info circle" />} position="right center"></Popup>}
                </Table.Cell>
                <Table.Cell className='flag-code'><span>{accountFeature.flagCodeName}</span></Table.Cell>
                <Table.Cell className='flag-value'>
                  <FeatureFlagValue dataType={accountFeature.dataType} value={accountFeature.value} 
                    onChange={(value) => setAccountFeatureValue(accountFeature, value)} disabled={isReadOnly(accountFeature)}/>
                </Table.Cell>
                <Table.Cell className='delete-override'>
                  <Button className={'primary' + (accountFeature.isDefaultValue ? ' is-default': '')}  onClick={() => {onDeleteOverrideClick(accountFeature.featureFlagId)}}  disabled={isReadOnly(accountFeature)}>Delete Override</Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ))}
       
       {account.deletedDate ? null : (
          <div className="footer">
            <CommentInput placeholder="Comment."
                  value={updates?.comments} onChange={setComments}/>
            <br />
            <Button className="primary" onClick={onSaveClick} loading={isSaving} disabled={!canSave()}>
              Save
            </Button>
          </div>
        )}
      </div>

      <Modal open={deleteOverrideModalOpen} onClose={closeDeleteOverrideModal} dimmer="inverted">
        <Modal.Header>Confirm Override Delete</Modal.Header>
        <Modal.Content className='delete-override-modal'>
          <div className='description'>
            <span>Deleting this override will revert this feature back to its default value for this account.</span>
            <span>If you would like to continue enter a comment and click <strong>Confirm</strong>.</span>
          </div>
          <CommentInput placeholder="Comment."
                  value={deleteOverrideComment} onChange={setDeleteOverrideComment}/>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={closeDeleteOverrideModal} color="blue" >
            Cancel
          </Button>
          <Button primary onClick={onDeleteOverrideConfirmClick} disabled={!deleteOverrideComment}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

